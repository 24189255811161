<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A solution contains
        <number-value :value="soluteMass" unit="\text{g}" />
        of urea,
        <chemical-latex content="CO(NH2)2" />
        (a non-volatile solute) in
        <number-value :value="solventMass" unit="\text{kg}" />
        of water.
      </p>

      <p class="mb-3">a) What is the mole fraction of water in this solution?</p>

      <calculation-input
        v-model="inputs.XH2O"
        class="mb-5"
        prepend-text="$X_\ce{H2O}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) If the vapour pressure of pure water at 25<sup>o</sup>C is 23.7 Torr, what is the vapour
        pressure of the solution,
        <stemble-latex content="$\text{P}_\text{soln},$" />
        assuming ideal behaviour?
      </p>

      <calculation-input
        v-model="inputs.vp"
        prepend-text="$\text{P}_\text{soln}:$"
        append-text="$\text{Torr}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question413',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        XH2O: null,
        vp: null,
      },
    };
  },
  computed: {
    soluteMass() {
      return this.taskState.getValueBySymbol('soluteMass').content;
    },
    solventMass() {
      return this.taskState.getValueBySymbol('solventMass').content;
    },
  },
};
</script>
